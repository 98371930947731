/* Reset */ html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure,  footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video { margin:0; padding: 0; border: 0; outline: 0; font-size: 100%; font: inherit; vertical-align: baseline;} article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section { display: block;}ol, ul { list-style: none;} blockquote, q { quotes: none;} blockquote:before, blockquote:after, q:before, q:after {  content: '';  content: none; } a { color:inherit;  text-decoration: none; }
/* Clearfix */ .cf:before, .cf:after { content: " "; display: table; } .cf:after { clear: both; }
/* Box Sizing */ *, *:before, *:after { box-sizing: border-box; -moz-box-sizing:border-box; }

html {
	font-size: 62.5%;
}

img {
	max-width: 100%;
}