@import '_reset';
@import '_base';

$break-large: 1080px;
$break-medium: 850px;
$break-small: 680px;

$pinkred: #ff0080;
$green: #2bffa8;

/*
// :::Type
//-------------------------------------------------- */

@font-face {
	font-family: 'Gerstner-Programm';
	src: url('/fonts/Gerstner-ProgrammRegular.woff')
			format('woff'),
		url('/fonts/Gerstner-ProgrammRegular.woff2')
			format('woff2');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Gerstner-Programm';
	src: url('/fonts/Gerstner-ProgrammBold.woff')
			format('woff'),
		url('/fonts/Gerstner-ProgrammBold.woff2')
			format('woff2');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Gerstner-Programm';
	src: url('/fonts/Gerstner-ProgrammItalic.woff')
			format('woff'),
		url('/fonts/Gerstner-ProgrammItalic.woff2')
			format('woff2');
	font-weight: normal;
	font-style: italic;
}

.wf-loading {
	h1,
	h2,
	h3,
	h4,
	p,
	li,
	h5,
	input {
		visibility: hidden;
	}
}

$neue: 'Gerstner-Programm', 'Franklin Gothic Medium', 'Franklin Gothic',
	'Helvetica Neue', 'Helvetica', helvetica, sans-serif;

body {
	font-family: $neue;
	font-weight: 500;
	letter-spacing: 0.035em;
}
h1 {
	font-size: 20rem;
}
h2 {
	font-size: 14rem;
}
h3 {
	font-size: 9rem;
}
h4,
p,
li,
input {
	font-size: 1.1rem;
	line-height: 1.5em;
}
h5 {
	font-size: 1.45rem;
}

br {
	line-height: 2em;
}

/*
// :::Shared
//-------------------------------------------------- */

body {
	background: black;
	color: white;
}

body.has-overlay {
	overflow: hidden;
}

.flip {
	transform-origin: center;
	transform: rotate(180deg) translateZ(0px);
	transform-style: preserve-3d;
}

img.preload {
	position: fixed;
	top: -100px;
	left: -100px;
	height: 1px;
	width: 1px;
}

/*
// :::Menu
//-------------------------------------------------- */

body.loaded {
	section.advent-menu,
	section.background {
		opacity: 1;
	}
}

section.background {
	@mixin fixed-full-size;
	z-index: -1;
	opacity: 0;
	transition: 0.6s opacity;

	video,
	img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
	}

	img {
		display: none;
	}
}

@media screen and(-webkit-min-device-pixel-ratio:0) {
	section.background {
		video {
			display: none;
		}
		img {
			display: block;
		}
	}
}

@media (-webkit-video-playable-inline) {
	section.background {
		video {
			display: block;
		}
		img {
			display: none;
		}
	}
}

section.advent-menu {
	opacity: 0;
	transition: 0.9s opacity;
	transition-delay: 1s;
	text-align: center;
	padding-top: 8rem;

	.luckyme-logo {
		max-width: 8rem;
	}
}

.years {
	margin: 16rem auto 8rem;
	display: flex;
	align-items: center;
	flex-direction: column;

	a.year-link {
		h2,
		h3 {
			display: inline-block;
		}

		.slash {
			font-family: 'Myriad Pro';
			font-weight: 100;
			font-size: 1em;
			margin-right: 0.2em;
		}

		&.disabled {
			pointer-events: none;
			color: rgba(255, 255, 255, 0.25);
		}
	}
}

.year-link:hover {
	color: $green;
}

/*
// :::Loading
//-------------------------------------------------- */

.fade {
	opacity: 0;
	transition: opacity 0.6s;
}

.fade--slow {
	transition: opacity 1.7s;
}

body.loaded {
	.fade {
		opacity: 1;
	}

	.fade--two {
		transition-delay: 0.6s;
	}

	.fade--three {
		transition-delay: 1.2s;
	}

	.fade--four {
		transition-delay: 2s;
	}
}

/*
// :::Landing
//-------------------------------------------------- */

section.landing {
	@mixin flex-center;
	height: 100vh;
	min-height: 45rem;
	max-height: 80rem;
	position: relative;
	background-color: black;

	.background,
	.carousel__image,
	.carousel {
		@mixin abs-full-size;
		background-size: cover;
		background-position: center;
	}

	h1 {
		color: white;
		letter-spacing: -0.015em;
		font-weight: 700;
	}

	.carousel__image {
		opacity: 0;
		transition: 0.3s;

		&.visible {
			opacity: 1;
		}
	}
}

/*
// :::Tracks
//-------------------------------------------------- */

section.main {
	background: black;
	color: white;
	padding: 7rem 2rem;
	min-height: 75rem;
}

.tracks {
	margin-left: -0.75rem;
}

.track {
	width: calc((100% / 6) - 0.75rem);
	margin-left: 0.75rem;
	min-height: 7rem;
	display: inline-flex;
	flex-direction: column;
	margin-bottom: 2rem;
	transition: opacity 0.4s;
	opacity: 0;
}

.track__images {
	width: 100%;
	height: 2.5rem;
	margin-bottom: 0.5rem;
	position: relative;
}

.track__image {
	@mixin abs-full-size;
	backface-visibility: hidden;
	transition: opacity 0.3s, transform 1s;
}

.track.enabled {
	pointer-events: none;

	.track__image--front {
		transform: rotateX(-180deg);
	}

	p {
		opacity: 0;
		transition: opacity 0.6s linear 1s, color 0.3s;
	}
}

.track__image--reverse,
.track.disabled .track__image {
	filter: grayscale(1) contrast(2);
}

.track__image--reverse {
	transform: scale(1, -1);
}

body.loaded .track.in-view {
	pointer-events: initial;
	opacity: 1;

	.track__image--front {
		transform: none;
	}

	.track__image--reverse {
		transform: rotateX(180deg) scale(1, -1);
	}

	p {
		opacity: 1;
	}
}

.track__download {
	position: absolute;
	left: calc(50% - 1rem);
	bottom: 0.5rem;
	color: white;
	width: 2rem;
	border-bottom: 3px solid;
	text-align: center;
	line-height: 1;
	opacity: 0;
	transition: 0.3s;

	.arrow {
		font-size: 1.4rem;
		transform: translateY(-0.5rem);
		transition: inherit;
	}
}

.track__link:hover {
	color: $pinkred;

	.track__image--front {
		opacity: 0.65;
	}

	.track__download {
		opacity: 1;

		.arrow {
			transform: none;
		}
	}
}

.main__text {
	display: flex;
	margin-top: 7rem;
	justify-content: space-between;
	flex-wrap: wrap;

	.body {
		flex-basis: 33%;
		text-align: justify;
	}

	.donate {
		flex-basis: 100%;
		margin-top: 3rem;
	}

	.button-like {
		@mixin flex-center;
		width: calc((100% / 6) - 0.75rem);
		height: 2.5rem;
		padding: 0.7rem 1.2rem;
		border: 1px solid;
		transition: 0.2s;
		background: white;
		color: black;
		border: 1px solid white;

		&:hover {
			color: white;
			background: transparent;
		}
	}

	.socials {
		display: flex;
		justify-content: space-between;
		flex-basis: calc(100% / 6);
		padding-left: 0.75rem;
		margin-right: calc(100% / 6);
	}
}

.socials {
	display: flex;
	justify-content: flex-end;
	align-items: top;

	.icon {
		width: 3rem;
		height: 3rem;
		position: relative;

		svg {
			position: absolute;
			fill: white;
			transition: 0.2s;
		}

		svg.hover {
			opacity: 0;
		}

		&:hover {
			svg {
				opacity: 0;
			}
			svg.hover {
				opacity: 1;
			}
		}
	}
}

.icon--apple {
	display: none;
}

/*
// :::Shop
//-------------------------------------------------- */

section.shop {
	background: white;
	color: black;

	.shop__item-title {
		text-align: center;
		margin: 1em 0;
		font-size: 22px;
	}

	.shop__inner {
		transition: 0.3s;
		padding: 20px 10px 30px;

		&:hover {
			img {
				opacity: 0.5;
			}
			background-color: rgba(0, 0, 0, 0.2);
		}
	}

	img {
		transition: inherit;
		max-width: 54rem;
		margin: 0 auto;
		display: block;
	}
}

/*
// :::Footer
//-------------------------------------------------- */

section.footer {
	background-color: white;
	display: none;

	.footer-banner {
		position: relative;
		width: 100%;
		bottom: 0;
		left: 0;
		height: 9rem;
		padding: 2rem;
	}

	.footer-banner::before,
	.footer-banner::after {
		content: '';
		position: absolute;
		top: calc(50% - 0.1rem);
		background-color: black;
		width: calc(50% - 8rem);
		height: 0.1rem;
	}

	.footer-banner::before {
		right: calc(50% + 5rem);
	}
	.footer-banner::after {
		left: calc(50% + 5rem);
	}

	.logo {
		width: 5rem;
		height: 5rem;
		position: relative;
		margin: 0 auto;

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			transition: 0.4s;
		}
	}
}

/*
// :::Mailer
//-------------------------------------------------- */

section.subscribe {
	@mixin invisible;
	@mixin fixed-full-size;
	@mixin flex-center;
	background-color: rgba(0, 0, 0, 0.9);
	color: white;
	transition: 0.4s;

	&.visible {
		@mixin visible;
	}

	.ex {
		position: absolute;
		top: 2rem;
		right: 2rem;
		width: 3rem;
		height: 3rem;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: calc(50% - 1px);
			left: 0;
			height: 2px;
			width: 135%;
			transform-origin: 50%;
			background-color: white;
		}

		&:before {
			transform: rotate(-45deg);
		}

		&:after {
			transform: rotate(45deg);
		}
	}
}

.signup {
	position: relative;
	width: calc(100% - 2rem);
	max-width: 130rem;

	form,
	input {
		width: 100%;
		font-weight: 700;
	}

	input,
	button {
		appearance: none;
		background: none;
		border: none;
		color: inherit;
		transition: 0.3s;
	}

	form {
		perspective: 25rem;
	}

	.message {
		position: absolute;
		bottom: 100%;
		left: 0;
		width: 100%;
		color: $pinkred;
		text-align: center;
		transition: 0.3s;

		a {
			border-bottom: 2px solid;
		}
	}

	input {
		overflow: visible;
		text-align: center;
		border-bottom: 4px solid $green;
		@extend h2;
		line-height: 20rem;

		&:focus::placeholder {
			color: transparent;
		}

		&::placeholder {
			@extend h1;
			color: white;
			z-index: 100;
			transform: translateY(33%) rotate(180deg);
		}
	}

	button[type='submit'] {
		@mixin invisible;
		position: absolute;
		bottom: -10rem;
		left: calc(50% - 9rem);
		width: 18rem;
		cursor: pointer;

		&:hover {
			color: $green;
		}

		h3 {
			transition: 0.3s;
		}
	}

	.signup__thank-you {
		position: absolute;
		top: 50%;
		pointer-events: none;
		width: 100%;
		text-align: center;
		transform: rotateX(90deg);
		transition: 0.5s;
		transition-delay: 0.3s;
		opacity: 0;
	}

	form.valid {
		.message {
			opacity: 0;
		}

		button[type='submit'] {
			@mixin visible;
		}
	}

	form[data-state='error'] {
		.message {
			opacity: 1;
		}
	}

	form[data-state='thinking'] {
		button {
			h3 {
				opacity: 0;
			}
			.thinking__dots {
				opacity: 1;
			}
		}
	}

	form.valid[data-state='success'] {
		input,
		button {
			@mixin invisible;
		}

		button h3 {
			opacity: 0;
		}

		.signup__thank-you {
			opacity: 1;
			transform: none;
		}
	}
}

.thinking__dots {
	@mixin abs-full-size;
	@mixin flex-center;
	opacity: 0;
	transition: 0.3s;
	transition-delay: 0.3s;
}

.thinking__dot {
	animation: dot-blink 0.75s infinite;
	animation-direction: alternate;
	display: inline-block;
	opacity: 1;
	width: 0.8rem;
	height: 0.8rem;
	background-color: white;
	border-radius: 1rem;
	margin: 0 0.5rem;
}

@keyframes dot-blink {
	from {
		opacity: 0;
	}
	to {
		opacty: 1;
	}
}

@for $i from 1 to 3 {
	.thinking__dot:nth-of-type($i) {
		animation-delay: resolve($i * 0.2s);
	}
}

/*
// :::Queries
//-------------------------------------------------- */

@media screen and (max-width: $break-large) {
	.main__text {
		.body {
			flex-basis: 50%;
		}

		.socials {
			flex-basis: 25%;
			margin-right: 0;
		}
	}

	.track,
	.main__text .button-like {
		width: calc((100% / 4) - 0.75rem);
	}
}

@media screen and (max-width: $break-medium) {
	h1 {
		font-size: 12rem;
	}

	.signup input {
		font-size: 6rem;
		line-height: initial;
	}

	.main__text {
		.socials {
			flex-basis: 33%;
		}
	}

	.track,
	.main__text .button-like {
		width: calc((100% / 3) - 0.75rem);
	}
}

@media screen and (max-width: $break-small) {
	h1 {
		font-size: 7rem;
	}

	.signup input {
		font-size: 4rem;
	}

	section.landing {
		max-height: 75rem;
	}

	section.shop {
		.shop__inner {
			padding: 0 15%;
		}

		img {
			max-width: 100%;
		}
	}
	.main__text {
		flex-direction: column;

		.body,
		.socials {
			/* max-width: 40rem; */
			padding-right: calc(50% + 0.75rem);
			flex-basis: 50%;
		}

		.donate {
			margin-top: 3rem;
		}

		.socials {
			margin-top: 3rem;
			margin-left: 0;
			order: 3;
			padding-left: 0;

			.icon {
				width: 3.5rem;
			}
		}
	}

	.track,
	.main__text .button-like {
		width: calc((100% / 2) - 0.75rem);
	}
}

@media screen and (max-width: 525px) {
	.track {
		width: 100%;
	}

	.signup input {
		font-size: 2.7rem;
	}

	.main__text {
		.body {
			/* max-width: 40rem; */
			padding-right: 0;
			flex-basis: 100%;
			max-width: 40rem;
		}
	}
}
